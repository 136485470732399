


















































































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
import fetchGetUserApiQuery from '@/api/husuraApi/getUserApi'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import QrcodeApi from '@/api/qrcode'
import getMintStatusApi from '@/api/husuraApi/getMintStatusApi'
import fetchCreateWssoNFTQuery from '@/api/husuraApi/getWssoNFTApi'

@Component({
  name: 'CastingNftDialog',
  components: { vueQr }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: () => {
      return {
        projectItemId: ''
      }
    }
  }) private initData!: {
    projectItemId: string
  }

  private projectItemId = ''
  private qrLink = ''
  private user_account = ''
  private wssoList = [
    {
      project: {
        collection_id: '',
        project_name: '',
        project_owner_did: '',
        project_wsso: {
          equipped_capacitor: '',
          id: '',
          power_generation_type: '',
          project_address: '',
          project_coordinate: '',
          project_id: '',
          project_no: '',
          project_owner_name: '',
          project_region: '',
          methodology_name: '',
          plan_file: '',
          plan_file_name: '',
          plan_file_chain_hash: '',
          examine_report: '',
          examine_report_name: '',
          examine_report_chain_hash: ''
        }
      },
      agent_serial_number: '',
      agent_count: '',
      agent_at: '',
      id: '',
      item_no: '',
      score: '',
      verify_file: '',
      verify_file_name: '',
      verify_file_chain_hash: '',
      cert_image: '',
      cert_image_hash: ''
    }
  ]

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      this.projectItemId = this.initData.projectItemId
      this.castingNftIRecQuery()
    }
  }

  private get dialogVisible() {
    return this.visible
  }

  private set dialogVisible(value: boolean) {
    if (!value) {
      this.close(true)
    }
  }

  private castingNftIRecQuery(refresh = true) {
    if (refresh) {
      fetchCreateWssoNFTQuery(this.projectItemId)
        .then(async({ data: res }) => {
          const { data } = res
          this.wssoList = data.project_items
          this.getUserIRecQuery()
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  private getUserIRecQuery() {
    fetchGetUserApiQuery(this.wssoList[0].project.project_owner_did)
      .then(async({ data: res }) => {
        const { data } = res
        if (data) {
          this.user_account = data.users[0].user_account
          if (this.wssoList.length > 0) {
            const first = this.wssoList[0]
            const schemeValue = `incapture://mint?json=${btoa(
              encodeURIComponent(
                JSON.stringify({
                  type: 'wsso',
                  item_id: this.projectItemId,
                  ethereum: true,
                  owner: this.user_account,
                  ...first
                })
              )
            )}`
            const { key } = (await QrcodeApi.requestGetKey(schemeValue)).data
            this.qrLink = `incapture://mint?key=${key}`
            this.timer = setInterval(this.qrCodeSuccess, 3000)
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  timer: any = null
  private timerCleared = false
  qrCodeSuccess() {
    getMintStatusApi(this.projectItemId)
      .then(async({ data: res }) => {
        const { data } = res
        if (data && data.project_items_by_pk && data.project_items_by_pk.mint_status) {
          clearInterval(this.timer)
          this.timerCleared = true
          this.$message.success(<string>this.$t('WSSO_Project.Success'))
          this.dialogVisible = false
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  private close(refresh = false) {
    this.$emit('close', refresh)
    if (!this.timerCleared) {
      clearInterval(this.timer)
    }
  }
}
